<template>
  <div class="page-grid">
    <v-container class="category-block-header">
      <category-title :category="category" />
    </v-container>
    <div
      :style="row.style"
      v-for="(row, rowIndex) in $ebsn.meta(
        category,
        'template_model.GRID_CONFIG.rows'
      )"
      :key="rowIndex"
    >
      <v-container v-if="row.container">
        <v-row :class="`row-number-${rowIndex}`">
          <v-col
            v-for="(column, columnIndex) in row.cols"
            :key="columnIndex"
            :class="`col-number-${columnIndex}`"
            class="py-0"
            :cols="column.xs || 12"
            :sm="column.sm"
            :md="column.md"
            :lg="column.lg"
            :xl="column.xl"
          >
            <category-block
              :position="column.position"
              :target="category"
              :container="false"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-row v-else :class="`row-number-${rowIndex}`" :key="rowIndex">
        <v-col
          v-for="(column, columnIndex) in row.cols"
          :key="columnIndex"
          :class="`col-number-${columnIndex}`"
          :cols="column.xs || 12"
          :sm="column.sm"
          :md="column.md"
          :lg="column.lg"
          :xl="column.xl"
        >
          <category-block
            :position="column.position"
            :target="category"
            :container="column.container ? column.container : true"
          />
        </v-col>
      </v-row>
    </div>

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "PageGrid",
  mixins: [category, deliveryReactive, login],
  components: {
    CategoryTitle
  }
};
</script>
